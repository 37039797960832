<template>
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask
			id="mask0_186_7880"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="24"
		>
			<rect width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_186_7880)">
			<path
				d="M12 8.3308L17.6538 13.9846L16.6 15.0384L12 10.4384L7.40001 15.0384L6.34619 13.9846L12 8.3308Z"
				fill="currentColor"
			/>
		</g>
	</svg>
</template>
